import { OrbitControls, Stage } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Stars } from "@react-three/drei";
import { Model } from "../objects/Iemct_logo_three";



export default function Maing(){
  return(
    <>
    <div className="flex sm:w-full w-[75%] sm:h-80 h-96 pb-2 cursor-grab mx-auto justify-center rounded-md mb hover:-translate-y-5 duration-200 " id="tdlogo"> 
    <Canvas camera={{ position:[10,20,200], zoom:5 , fov:70, }}>
      <Stage>
      <mesh>
        <pointLight position={[0,-18,-60]}/>
        <Stars />
        <Model scale={[2,2,2]} position={[0,-18,60]} rotation={[-Math.PI/2,0,0]}/>
        <OrbitControls />
      </mesh>
      </Stage>
    </Canvas>
    </div>
    
    </>
  )
}
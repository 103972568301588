import React from 'react'
import fa from './assets/Fa.jpg'
import pres from './assets/presimg2.jpeg'
import {motion} from 'framer-motion'

function Addresses() {
  return (
    <div className='p-5 bg-secondback text-iemctwhite '>
      <div className={` overflow-auto container w-[100%] h-auto flex-grow border-8 border-iemctwhite p-5 text-center md:text-8xl text-4xl pt-5 mb-5 font-raleway rounded-md font-semibold text-iemctwhite bg-backcol`}>
       Our Leaders
    </div>

    <div className='lg:mt-14 mt-0 mb-2 font-montserrat  sm:w-3/4 text-justify sm:text-lg text-md'>
      Hear from our Faculty Advisor and our Club President:
      </div>

    {/* Faculty Advisor */}
      <motion.div
    initial={{x:200,opacity:0}}
    whileInView={{x:0,opacity:1,transition:{duration:1}}}
    viewport={{ once: true }}  className='p-2 flex flex-col-reverse lg:flex-row text-iemctwhite justify-center'>

      <div className='lg:mt-14 mt-0 mb-2 font-montserrat  sm:w-[80%]  sm:text-lg text-sm'>
      IE Mechatronics is a student technical club registered under Institute of Engineers (India).
      <span className='font-semibold inline-block text-iemctredhov'> This is not just a club; it's a community of like-minded individuals who are passionate about robotics and automation.</span> We are committed to fostering personal and professional growth, creating memorable experiences, and making a positive impact on our campus and beyond. As a team our work focuses on social innovations through continuous learning and peer support. Some of our prime activities include school visits for upcoming technology discussions, workshops like Looking into the future, group projects etc. We would be delighted to have you on board for collaborative research, whether you are a current member, or someone interested in joining; we look forward to welcoming you to our club.<br/>
      <span className='font-semibold inline-block text-iemctredhov'>-Professor Ishawar Bhiradi, Mechatronics Department</span> 
      
      </div>
        <div  className=' img border-iemctredhov duration-200 mx-auto'>
          <img src={fa} alt="Prof.Ishwar" className='w-auto h-auto rounded-md' />
        </div>
      </motion.div>

      {/* President */}

      <motion.div
    initial={{x:-200,opacity:0}}
    whileInView={{x:0,opacity:1,transition:{duration:1}}}
    viewport={{ once: true }}  className='p-2 flex flex-col lg:flex-row text-iemctwhite justify-center'>
       <div  className=' img border-iemctredhov duration-200 mx-auto'>
          <img src={pres} alt="President" className='w-auto h-auto rounded-md' />
        </div>
      <div className='lg:mt-14 mt-0 mb-2 font-montserrat  sm:w-[80%]  sm:text-lg text-sm '>
      As the President of IE Mechatronics, I am thrilled to welcome you to our official website. This platform serves as a hub for all things related to our vibrant club, connecting our passionate members, showcasing our accomplishments, and providing a glimpse into our exciting future. At IE Mechatronics, we believe that mechatronics engineering is the cornerstone of innovation, driving the development of intelligent and interconnected systems that transform industries and enhance our lives. We encourage you to embrace challenges, seek knowledge, and cultivate a collaborative spirit. Your contributions, big or small, play a crucial role in shaping our club's success and your own, which I have thoroughly experienced in my time in this club. Our club's achievements are a testament to the dedication and talent of our members. Their passion, dedication, and commitment is the driving force behind our achievements. As we continue to navigate the ever-evolving landscape of mechatronics, <span className='font-semibold inline-block text-iemctredhov'>IE Mechatronics remains committed to providing our members with the resources and opportunities to excel.</span> We are proud to be a driving force in shaping the future of mechatronics engineering, and we invite you to join us on this exciting journey. 
      <br/>
      Connecting the dots to engineer the future.<br/>
      <span className='font-semibold inline-block text-iemctredhov'>-Idhika Jakhetiya, President-IE Mechatronics
</span> 
      </div>
       
      </motion.div>



    </div>
  )
}

export default Addresses

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './App.css';
import {BrowserRouter} from 'react-router-dom';
import AnimatedRoutes from './AnimatedRoutes';

// use monterserat for descriptions and raleway for heading    

function App() {
  return (
    <div className='App overflow-auto'>
      <BrowserRouter>
        <Navbar />
      <AnimatedRoutes />    
      <Footer />
      </BrowserRouter>  

  </div>
  );
}

export default App;

import React from 'react'
// import { faLinkedin,faInstagram , faGithub } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './Cards.css';
import {  faLinkedin,  faInstagram, faGithub} from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';

const baseURL="https://randomuser.me/api/?results=9"

const AlumniCard = () => {

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    axios.get(baseURL).then((response) => {
      setData(response.data.results);
    });
  }, []);

  if(!data) return;

  return (
   <>
   <div className='font-[Outfit] text-white'>
   <div className='md:text-7xl text-3xl text-gray-500 pt-10 pb-10 font-bold font-montserrat flex flex-col'>
    <div className='text-iemctwhite'>
     The Board
     </div>
     <div className='text-gray-200 '>
    <hr />
     </div>
     <div className='text-iemctcard2 md:text-5xl text-2xl font-numeric text-iemctred'>
     2021-22
     </div>
     </div>
     
        <div className="container relative z-1 flex justify-center items-center flex-row flex-wrap ">
        {data.map((board)=>(
    <div className="card bg-iemctcard hover:bg-iemctcardhov">
      <div className="content">
        <div className="img"><img alt=' ' src={board.picture.large}/></div>
        <div className="cardContent">
          <h3>{board.name.first} {board.name.last}<br/><span id="position">Web Developer</span></h3>
        </div>
      </div>
      <ul className="sci">
        <li >
          <button alt='' ><FontAwesomeIcon icon={faLinkedin} size='lg'></FontAwesomeIcon></button>
        </li>
        <li >
          <button alt='' href="#"><FontAwesomeIcon icon={faInstagram} size='lg'></FontAwesomeIcon></button>
        </li>
        <li >
          <button alt='' href="#"><FontAwesomeIcon icon={faGithub} size='lg' ></FontAwesomeIcon></button>
        </li>
        <span id="work">Works at Google</span>
       
    </ul>
    </div>
        ))}
  
  </div>   
   </div> 
   </>
  )
}

export default AlumniCard
import React from 'react'
import './master.css';
import {motion} from 'framer-motion'
import SmG from './SubsytemGfx/SocialMedia';
import ManagG from './SubsytemGfx/Management';
import AIDG from './SubsytemGfx/AID';
import ElecG from './SubsytemGfx/Electronics';
import MechG from './SubsytemGfx/Mechanical';
import RoboG from './SubsytemGfx/Robotics';



function Subsystems() {
  return (
    <>
   
    <div id="nav" className='p-5 pb-10 bg-backcol'>
    <div className={`overflow-auto container w-[100%] h-auto flex-grow border-8 border-iemctwhite p-5 text-center md:text-8xl text-4xl pt-5 mb-5 font-raleway rounded-md font-semibold text-iemctwhite bg-backcol mx-1 flex-wrap`}>
       Our Subsystems
    </div>

    <div className="flex justify-center md:flex-row flex-col flex-wrap">
      {/* cards */}
      <motion.div
    initial={{x:200,opacity:0}}
    whileInView={{x:0,opacity:1,transition:{duration:1}}}
    viewport={{ once: true }}
   >
    <div className=" max-w-sm rounded border overflow-hidden m-auto sm:mx-10 my-10 h-64  w-64 cursor-pointer bg-secondback" id="cards">
  <div className="px-6 py-4 hover:z-10">
    <AIDG />
    <div className='text-lg font-montserrat flex justify-center text-center z-10'>
      AI & D
    </div>
  </div>
</div>
</motion.div>


<motion.div
    initial={{y:200,opacity:0}}
    whileInView={{y:0,opacity:1,transition:{duration:1}}}
    viewport={{ once: true }}
   >
<div className="max-w-sm rounded overflow-hidden border h-64 m-auto sm:mx-10 my-10 w-64 cursor-pointer bg-secondback" id="cards">
  <div className="px-6 py-4">
   <ElecG/>  
   <div className='text-lg font-montserrat flex justify-center text-center z-10'>
      Electronics
    </div>
  </div>
</div>
</motion.div>


<motion.div
    initial={{x:-200,opacity:0}}
    whileInView={{x:0,opacity:1,transition:{duration:1}}}
    viewport={{ once: true }}
   >
<div className="max-w-sm rounded overflow-hidden border h-64 m-auto sm:mx-10 my-10 w-64 cursor-pointer bg-secondback" id="cards">
  <div className="px-6 py-4">
    <MechG />
    <div className='text-lg font-montserrat flex justify-center text-center z-10'>
      Mechanical
    </div>
  </div>
</div>
</motion.div>

<motion.div
    initial={{y:200,opacity:0}}
    whileInView={{y:0,opacity:1,transition:{duration:1}}}
    viewport={{ once: true }}
   >
<div className="max-w-sm rounded overflow-hidden border h-64 m-auto sm:mx-10 my-10 w-64 cursor-pointer bg-secondback" id="cards">
  <div className="px-6 py-4">
  <RoboG />
    <div className='text-lg font-montserrat flex justify-center text-center z-10'>
     Robotics
    </div>
  </div>
</div>
</motion.div>

<motion.div
    initial={{x:200,opacity:0}}
    whileInView={{x:0,opacity:1,transition:{duration:1}}}
    viewport={{ once: true }}
   >
<div className="max-w-sm rounded overflow-hidden border h-64 m-auto sm:mx-10 my-10 w-64 cursor-pointer bg-secondback" id="cards">
  <div className="px-6 py-4">
    <ManagG />
    <div className='text-lg font-montserrat flex justify-center text-center z-10'>
      Management
    </div>
  </div>
</div>
</motion.div>

<motion.div
    initial={{x:-200,opacity:0}}
    whileInView={{x:0,opacity:1,transition:{duration:1}}}
    viewport={{ once: true }}
   >
<div className="max-w-sm rounded overflow-hidden border h-64 m-auto sm:mx-10 my-10 w-64 cursor-pointer bg-secondback" id="cards">
  <div className="px-6 py-4">
   
    <SmG  />
    <div className='text-lg font-montserrat flex justify-center text-center pb-96 z-10'>
      Social Media
    </div>
  </div>
</div>
</motion.div>

  </div>


    </div>
    </>
  )
};

export default Subsystems
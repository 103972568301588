import React from 'react'
import Projects from '../Projects';
import {motion} from 'framer-motion'

function Work() {
  React.useEffect(() => {
    window.scrollTo({top:0,left:0})
    }, )  
  return (
    <div className=' bg-secondback'>
       <motion.div className='p-5 pb-10'
    initial={{width:0}}
    animate={{width:"100%"}}
    exit={{x:-window.innerWidth, transition:{duration:0.3}}}>
     <Projects />
    </motion.div>
      
    </div>
  )
}

export default Work

import React from 'react'


function Membertext(props) {
 
   
  return (
    <div className=' text-white h-auto w-auto rounded-xl flex justify-center flex-wrap max-w-6xl m-auto tracking-tight py-10 bg-iemctcardhov sm:text-xl text-lg px-5'>
      {props.data}
    </div>
  )
}

export default Membertext

import React from 'react'
import '../master.css'
import EventCard from '../EventCard'
import {motion} from 'framer-motion'


function Events() {
  React.useEffect(() => {
    window.scrollTo({top:0,left:0})
    }, )
  return (
    <motion.div className='p-5 pb-10 bg-secondback'initial={{width:0}}
    animate={{width:"100%"}}
    exit={{x:-window.innerWidth, transition:{duration:0.3}}}>
      <div className={` overflow-auto container w-[100%] h-auto flex-grow border-8 border-iemctwhite p-5 text-center md:text-8xl text-4xl pt-5 mb-5 font-raleway rounded-md font-semibold text-iemctwhite bg-backcol`}>
       A Spectacular Turn of Events  
    </div>
    <motion.div
        initial={{y:200,opacity:0}}
        whileInView={{y:0,opacity:1,transition:{duration:1}}}
        viewport={{ once: true }}
       > 
    <EventCard />
    </motion.div>
    </motion.div>
  )
}

export default Events

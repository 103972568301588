import React,{useState,useEffect} from 'react'
import {Link} from 'react-router-dom';
import './master.css';
import JoinForm from './JoinModal';



function Navbar() {
   const [toggle, settoggle] = useState('hidden ')

 const prop=` w-full md:block sm:w-auto sm:mt-8 ${toggle}`


 const [prevScrollPos, setPrevScrollPos] = useState(0);
const [visible, setVisible] = useState(true)

const handleScroll = () => {
    const currentScrollPos = window.scrollY

    if(currentScrollPos > prevScrollPos){
        setVisible(false)
    }else{
        setVisible(true)
    }

    setPrevScrollPos(currentScrollPos)
}

useEffect( () => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll)
})


  return (
  <div className=' overflow-clip sm:pb-24 pb-16  font-montserrat font-semibold'> 
<nav  className={`px-2 sm:px-4 sm:py-1 py-3 fixed top-0 left-0 right-0 z-10 overflow-clip bg-backcol fixed-top h-auto rounded-md ${visible ? '' : 'opacity-0'}`}>
  <div className="container flex flex-wrap items-center justify-between mx-auto">

    <Link to="/">
        <button className='mt-2'>
        <svg  className="lg:h-[35px] h-6  flex justify-center mx-5 hover:scale-125 hover:translate-y-2 duration-150 scale-105" viewBox="-1 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnsSketch="http://www.bohemiancoding.com/sketch/ns" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>home</title> <desc>Created with Sketch Beta.</desc> <defs> </defs> <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" sketchType="MSPage"> <g id="Icon-Set" sketchType="MSLayerGroup" transform="translate(-569.000000, -879.000000)" fill="#FFFEFC"> <path d="M591.967,907 C591.967,908.104 591.075,909 589.975,909 L578.025,909 C576.925,909 576,908.104 576,907 L576,891.625 L584,881.93 L592,891.594 L591.967,907 L591.967,907 Z M584,879 L568.991,896.657 L570.399,898.071 L574,894 L574,907 C574,909.209 575.825,911 578.025,911 L589.975,911 C592.175,911 594,909.209 594,907 L594,894 L597.601,898.071 L599.009,896.657 L584,879 L584,879 Z" id="home" sketchType="MSShapeGroup"> </path> </g> </g> </g></svg>
        </button>
    </Link>

    <button data-collapse-toggle="navbar-sticky" type="button" className="inline-flex items-center p-2 ml-3 text-sm text-iemctwhite rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 " aria-controls="navbar-default" aria-expanded="false" onClick={ ()=>{toggle==='hidden'?settoggle(' '):settoggle('hidden') }} 
    >
      
      <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Menu / Menu_Alt_02"> <path id="Vector" d="M11 17H19M5 12H19M11 7H19" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
    </button>

    <div className={prop} id="navbar-sticky">
      <ul className="flex flex-col p-4 md:flex-row md:space-x-10 md:text-sm md:font-medium md:border-0 px-16 ">
      {/* <li>
          <Link to="/" className=" md:text-xl  block py-2 pl-3 pr-4 text-gray-300 rounded hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-gray-50 md:p-0">
            <button>
            Home
            </button>
            </Link>
        </li> */}

         {/* JOIN BUTTON */}
         <li>
          {/* <div className=" md:text-xl block py-2 pl-3 pr-4 text-gray-300 rounded hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-gray-50 md:p-0 ">
            <button className='border-2 border-gray-300 hover:border-gray-50 px-2'>
            Join
            </button>
            </div> */}
            <JoinForm />
        </li>
        <li>
          <Link to="/projects" className=" md:text-xl block py-2 pl-3 pr-4 text-gray-300 rounded hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-gray-50 md:p-0">
            <button>
            Projects
            </button>
            </Link>
        </li>
        <li>
          <Link to="/events" className=" md:text-xl block py-2 pl-3 pr-4 text-gray-300 rounded hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-gray-50 md:p-0">
            <button>
            Events
            </button>
            </Link>
        </li>
        <li>
          <Link to="/members" className=" md:text-xl block py-2 pl-3 pr-4 text-gray-300 rounded hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-gray-50 md:p-0">
            <button>
            Members
            </button>
            </Link>
        </li>
        {/* <li>
          <Link to="/alumni" className=" md:text-xl block py-2 pl-3 pr-4 text-gray-300 rounded hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-gray-50 md:p-0">
            <button>
            Alumni
            </button>
            </Link>
        </li> */}
         
      
      </ul>
    </div>

  </div>
</nav>
    </div>
  )
}

export default Navbar

import React from 'react'
import group from './assets/photo2.jpg'
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion'




function AboutUs() {
  return (
    <div className='p-5 pb-10 bg-secondback'>

<div className={` overflow-auto container w-[100%] h-auto flex-grow border-8 border-iemctwhite p-5 text-center md:text-8xl text-4xl pt-5 mb-5 font-raleway rounded-md font-semibold text-iemctwhite bg-backcol`}>
       Our Story 
    </div>


    <div className='p-2 flex flex-col-reverse lg:flex-row text-iemctwhite justify-center'>

      <div className='lg:mt-14 mt-0 mb-2 font-montserrat  sm:w-3/4 text-justify sm:text-lg text-md '>
      <span className='font-semibold inline-block text-iemctredhov'>IE Mechatronics</span>, founded in 2014 is the official Institution of Engineers (IE) Student Chapter for the Mechatronics department of the Manipal Institute of Technology, Manipal under the largest professional body of Engineers.<br/> The Institution of Engineers (IE), specialised in Mechatronics, Robotics, Automation, and Technical Workshops and Events.<br/> We are divided by six varied interests i.e. AI&D, Electronics, Robotics, Mechanical, Management	 and Social Media working together to ensure that the major aim of making the society a robot friendly is served.<br/>
      
      <span>
      <Link to="/members" className=' decoration-iemctredhov hover:underline'>
     Want to know more about our Members? Click Here
      </Link>
      </span>
      <br/>
      We teach, We innovate, We automate to let you  decide if automation is what our world requires, with no terms and conditions applied.
      
      </div>

     
        <motion.div
    initial={{x:200,opacity:0}}
    whileInView={{x:0,opacity:1,transition:{duration:1}}}
    viewport={{ once: true }}  className=' sm:w-1/2 px-4 lg:-mt-5 lg:py-2 h-auto py-4 flex-col'>
          <img src={group} alt="groupPhoto" className='hover:-translate-y-5 duration-200 sm:mt-8 sm:scale-[0.85] w-auto rounded-md border-4 border-iemctwhite' id="groupImg" />

          <div className=' text-gray-200 font-raleway md:text-[1.2vw] text-[2.5vw] flex-row mt-1 flex-wrap mx-auto'>
    <span>
    Members:
    </span>
    <span className='sm:mx-2 mx-1 px-2 py-1 text-iemctredhov font-bold'>
<CountUp 
suffix="+"end={200}>
   {({ countUpRef, start }) => (
       <VisibilitySensor onChange={start} delayedCall>
           <span ref={countUpRef} />
       </VisibilitySensor>
   )}
</CountUp>

</span>
| 
<span className='sm:mx-2 mx-1'>
    Events:
    </span>
<span className='sm:mx-2 mx-1 px-2 py-1 text-iemctredhov font-bold'>
<CountUp
suffix="+" end={50}>
   {({ countUpRef, start }) => (
       <VisibilitySensor onChange={start} delayedCall>
           <span ref={countUpRef} />
       </VisibilitySensor>
   )}
</CountUp>

</span>
| 
<span className='sm:mx-2 mx-1'>
   Projects:
    </span>
<span className='sm:mx-2 mx-1 px-2 py-1 text-iemctredhov font-bold'>
<CountUp 
suffix="+" end={15}>
   {({ countUpRef, start }) => (
       <VisibilitySensor onChange={start} delayedCall>
           <span ref={countUpRef} />
       </VisibilitySensor>
   )}
</CountUp>

</span>
</div>
        </motion.div>
        
      </div>

    </div>
  )
}

export default AboutUs

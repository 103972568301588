import React from 'react'
import {Routes,Route, useLocation} from 'react-router-dom';
import Events from './components/Pages/Events';
import Alumni from './components/Pages/Alumni';
import Home from './components/Pages/Home';
import Members from './components/Pages/Members';
import {AnimatePresence} from 'framer-motion';
import Work from './components/Pages/Work';


function AnimatedRoutes() {
  const location=useLocation();
  return (
  <AnimatePresence>
    <Routes location={location} key={location.pathname}>
            <Route exact path="/" element={<Home />}/>
            <Route  path="/events" element={<Events />}/>
            <Route  path="/members" element={<Members />}/>
            <Route  path="/alumni" element={<Alumni />}/>
            <Route  path="/projects" element={<Work />}/>
    </Routes>
  </AnimatePresence>
  )
}

export default AnimatedRoutes

import React from 'react';
import {AiFillMail} from 'react-icons/ai'


function CoreCards(props) {
 

  if(!props.data) return null
  return (
    <div className='flex flex-wrap justify-center m-1'>
{/* cards */}
{props.data.map((core)=>(
<div className='  text-white bg-iemctcard h-auto w-auto max-w-lg max-h-[30vh] rounded-xl p-1 hover:bg-iemctcardhov hover:-translate-y-5 duration-200 m-1 px-2'>      
      <div className='flex sm:px-2 p-0 content-center align-middle items-center justify-between'>
      
      <div>
     <div className=' p-2 text-2xl font-bold'>
     {core.name}
     </div> 
     <div className='text-iemctwhite'>
          <button className='m-3 hover:text-white hover:scale-110 duration-200' alt='' ><a href={`mailto:`+core.gmail} target="_blank" rel="noreferrer"><AiFillMail className=' mt-1 scale-125'/></a></button>
      </div>
        </div>
     </div>
     </div>
     ))}
     
</div>
    
  )
}

export default CoreCards

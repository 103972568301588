import React from 'react'
import AboutUs from '../AboutUs';
import Hero from '../Hero';
import {motion} from 'framer-motion'
import Subsystems from '../Subsystems';
import Addresses from '../Addresses';



function Home() {
  React.useEffect(() => {
    window.scrollTo({top:0,left:0})
    }, )
  return (
    <div className=' overflow-clip m-auto p-1 bg-backcol'>
    <motion.div
    initial={{opacity:0}}
    animate={{opacity:1}}
    exit={{x:-window.innerWidth, transition:{duration:0.3}}}>
     
     <Hero/>

     <motion.div
    initial={{opacity:0}}
    whileInView={{opacity:1,transition:{duration:1}}}
    viewport={{ once: true }}
   >
      <AboutUs />
     
    </motion.div>
    <motion.div className='p-5 pb-10'
    initial={{width:0}}
    animate={{width:"100%"}}
    exit={{x:-window.innerWidth, transition:{duration:0.3}}}>
       <Subsystems />
       </motion.div>
    
      <Addresses />

    </motion.div>
    </div>
  )
}

export default Home

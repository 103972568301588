import React from 'react'
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './Cards.css';
import {  faLinkedin,  faInstagram, faSpotify } from '@fortawesome/free-brands-svg-icons';
import {AiFillMail} from 'react-icons/ai'

const MemberCard = (props) => {
  
  if(!props.data) return;
  props.data.sort((a,b)=>a.id-b.id);

  return (
    <>
   
   <div className='font-[Outfit] text-white'>
        <div className="container relative z-1 flex justify-center items-center flex-reverse flex-wrap ">
        {props.data.map((board)=>(
    <div className="card bg-iemctcard hover:bg-iemctcardhov" key={board.position}>
      <div className="content">
        <div className="img"><img alt='' src={`${board.photo}`}/></div>
        <div className="cardContent">
          <h3>{board.name}<br/><span id="position">{board.position}</span></h3>
        </div>
      </div>
      {/* Socials */}
      <ul className="sci flex justify-between">
        <li >
          {board.spotify.length>1?
          <button type="button" alt=''><a href={board.spotify} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faSpotify} className=''></FontAwesomeIcon></a></button>
          :""}
        </li>
        <li >
          {board.instagram.length>1?
          <button type="button" alt=''><a href={board.instagram}target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} className=''></FontAwesomeIcon></a></button>
          :""}
        </li>
        <li >
          {board.gmail.length>1?
          <button type="button" alt=''><a href={`mailto:`+board.gmail} target="_blank" rel="noreferrer"><AiFillMail className=' mt-1'/></a></button>
          :""}
        </li>
        <li >
          {board.linkedIn.length>1?
          <button type="button" alt=''><a href={board.linkedIn} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} className=''></FontAwesomeIcon></a></button>
          :""}
        </li>
      
    </ul>
    </div> 
       ))}  
  </div>   
   </div> 
   </>  
  )
}

export default MemberCard
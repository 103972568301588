
import img from '../assets/mechanical.PNG'




export default function MechG(){
  return(
    <>
    <div style={{ width: "200px", height: "200px" ,}}>
    {/* <Canvas camera={{ fov: 35, zoom: 0.8 , position:[10,15,10]}} >
      <Stage>
      <mesh>
        <ambientLight />
       <Computer />
        <OrbitControls />
      </mesh>
      </Stage>
    </Canvas> */}
    <img src={img} alt="..." 
    // onMouseOver={e => (e.currentTarget.src = (img2))}
    // onMouseOut={e => (e.currentTarget.src = (img))}
    />
    </div>
    
    </>
  )
}
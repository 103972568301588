import React from 'react';
import './master.css';
import Maing from './HeroModel/iemctLogoModel';



function Hero() {
  return (
    
    <div  className=' bg-backcol m-0 flex flex-col justify-center content-center h-screen relative text-center mt-7 w-auto'>
       
     <div className=" align-middle h-auto text-center sm:p-52  mt-5 mb-5 flex-col justify-center ">
      <Maing />
  <div className='mb-0 cursor-pointer'>
    <span>
    <span id="title_hero_ie" className="font-raleway font-bold"> I </span>
    <span id="title_hero_ie" className="font-raleway font-bold"> E </span>
    <span id="title_hero_mct" className="font-raleway font-bold"> M </span>
    <span id="title_hero_mct" className="font-raleway font-bold"> C </span>
    <span id="title_hero_mct" className="font-raleway font-bold"> T </span>
    </span>
</div>
  <div className='font-raleway mt-1'>
  <h3 className="span loader "><span className="sub_hero ">I</span><span className="sub_hero">E</span><span className="sub_hero">&nbsp;</span><span className="sub_hero">M</span><span className="sub_hero">E</span><span className="sub_hero">C</span><span className="sub_hero">H</span><span className="sub_hero">A</span><span className="sub_hero">T</span><span className="sub_hero">R</span><span className="sub_hero">O</span><span className="sub_hero">N</span><span className="sub_hero">I</span><span className="sub_hero">C</span><span className="sub_hero">S</span><span className="sub_hero">&nbsp;</span><span className="sub_hero">M</span><span className="sub_hero">A</span><span className="sub_hero">N</span><span className="sub_hero">I</span><span className="sub_hero">P</span><span className="sub_hero">A</span><span className="sub_hero">L</span></h3>
  </div>

</div>
    </div>
  ) 
}

export default Hero
